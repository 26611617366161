// react & features import
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

//component imports
import Button from '../components/button';
import Event from '../components/event';
import Pastevent from '../components/pastEvent';
import Contact from '../components/contact';
import Group from '../components/group';

//styling & features imports
import '../../style/nav.css';
import 'animate.css/animate.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons/faRightFromBracket';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

// data imports
import { useFetchEvents, useFetchPastEvents } from '../../data/useFetch';
import { buttonLinks } from '../../data/links';
import { useNavigate } from 'react-router-dom';

const Nav = ({navbar, handleToggleCards}) => {
  // all the initial states declared with react hooks
  const [isGroupHovered, setIsGroupHovered] = useState(false);
  const [contactText, setContactText] = useState('Contact us');
  const [aboutText, setAboutText] = useState('About us');
  const [oureventsText, setOureventsText] = useState('Our events');
  const [showEvents, setShowEvents] = useState(false);
  const [showComingsoon, setShowComingsoon]= useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showElements, setShowElements] = useState(true);
  const [showOtherElements, setShowOtherElements] = useState(true);
  const [showAnotherMoreElement, setShowAnotherMoreElement] = useState(true);
  const [showDropdown, setShowdropdown] = useState(false);
  const [showGroupInfo, setShowGroupInfo] = useState(false);
  const [idToken, setIdToken]=useState(null);
  const [selectedYear, setSelectedYear] = useState();
  const navigate = useNavigate();
 
  // data fetching
  const events = useFetchEvents();
  const pastEvents = useFetchPastEvents();

  useEffect(()=>{
    setShowComingsoon(events.length === 0)
  }, [events] 
  );

  useEffect(()=>{
    setIdToken(localStorage.getItem('token'))
  }, [idToken]);


  // other event methods
  const handleMouseOnLogo = () => {
    if (showContact || aboutText === 'Go back' || showEvents) {
      return;
    }
    setIsGroupHovered(true);
    setShowGroupInfo(true);
  };

  const handleMouseLeaveLogo = () => {
    setIsGroupHovered(false);
    setShowGroupInfo(false);
  };

  const handleSelectedYear = (year) => {
    setSelectedYear(year)
  };


  const filteredPastEvents = selectedYear 
  ? pastEvents.filter(event => event.data.startsWith(selectedYear)) : [];


  const handleShowCards = () => {
    if (!showElements) {
      setAboutText('About us');
    } else {
      setAboutText('Go back');
    }
    handleToggleCards()
    setShowElements(!showElements);
    setShowdropdown(!showDropdown)
  };

  const handleShowEvents = () => {
    if (showEvents) {
      setOureventsText('Our events');
    } else {
      setOureventsText('Go back');
    }
      
    setShowEvents(!showEvents);
    setShowOtherElements(!showOtherElements);
    setShowdropdown(!showDropdown)
  };

  const handleShowContact = () => {
    if (showContact) {
      setContactText('Contact us');
    } else {
      setContactText('Go back');
    }
    setShowContact(!showContact);
    setShowAnotherMoreElement(!showAnotherMoreElement);
    setShowdropdown(!showDropdown)
  };

  const handleShowdropdown = () => {
    setShowdropdown(!showDropdown)
  };

  const handleLogOut = () =>{
    localStorage.clear()
    setIdToken(null)
  };

  // style js objects
  const spanimgStyle = {
    filter: isGroupHovered ? 'brightness(50%)' : '',
    transition: 'filter 0.8s ease',
  };

  return (
    <>
      <nav className="navbar navbar-dark bg-dark fixed-top border-bottom border-success ">
        <div className="container-fluid">
          <span className="navbar-brand mb-0 h1" style={spanimgStyle}>
            {idToken? (
              <>
                <FontAwesomeIcon  data-tooltip-id="logout" data-tooltip-content="Logout" icon={faRightFromBracket} id='logoutButton' style={{ fontFamily:'Celticmd'}} onClick={handleLogOut} className='btn btn-success' />
                <FontAwesomeIcon  data-tooltip-id="addEvent" data-tooltip-content="Add event" id='goAddEventButton' icon={faAdd} style={{ marginLeft:'2px', marginRight:'4px', fontFamily:'Celticmd'}} onClick={()=>navigate('/add_event')}className='btn btn-success'/>
              </>
            ):null}
            <img
              id="logoImg"
              onMouseEnter={handleMouseOnLogo}
              onMouseLeave={handleMouseLeaveLogo}
              src={navbar.logo.immagine}
              alt="Logo"
            />
            <a href={buttonLinks.href}>
              <img id="logoTitle" src={navbar.logo.titolo} alt="Title"/>
            </a>
          </span>
          <div className="dropDown">
            <div className="navbar-toggle" variant="" onClick={handleShowdropdown}>
              {!showDropdown ? (
                <span className="navbar-toggler-icon"></span>
              ) : <span style={{color:'grey'}}>X</span>}
            </div>
             <AnimatePresence>
                {showDropdown && (
                   <motion.div
                     initial={{y: -300, opacity: 0 }} 
                     animate={{y: 0, opacity: 1, transition:{duration:0.5}}} 
                     exit={{ opacity: 0}} 
                     id="dropdownId" 
                     className="dropdown border border-success bg-dark rounded p-3">
                      <div className='dropdown-item'>
                        {showAnotherMoreElement && showElements && (
                          <span className="responsiveEvents" onClick={handleShowEvents}>
                            {oureventsText}
                          </span>
                        )}
                     </div>
                     <div className='dropdown-item'>
                       {showAnotherMoreElement && showOtherElements && (
                         <span className="responsiveAboutUs" onClick={handleShowCards}>
                           {aboutText}
                         </span>
                       )}
                     </div>
                     <div className='dropdown-item'>
                       {showOtherElements && showElements && (
                         <span className="responsiveContactUs" onClick={handleShowContact}>
                           {contactText}
                         </span>
                       )}
                     </div>
                     <div className='dropdown-item'>
                         <Button />
                         {
                          idToken ? (
                            <>
                              <div style={{width: 'auto', margin: '0, auto'}}>
                                <FontAwesomeIcon icon={faRightFromBracket} id='responsiveLogout' onClick={handleLogOut}/>
                                <FontAwesomeIcon icon={faAdd} id='responsiveAddEvent' onClick={() => navigate('/add_event')}/>
                              </div>
                            </>
                          ):null
                         }
                     </div>
                   </motion.div>
                 )}
             </AnimatePresence>
          </div>
          {showAnotherMoreElement && showElements  && (
            <span className="ourEvents" onClick={handleShowEvents}>
              {oureventsText}
            </span>
          )}
          {showAnotherMoreElement && showOtherElements  && (
            <span className="aboutUs" onClick={handleShowCards}>
              {aboutText}
            </span>
          )}
          {showOtherElements && showElements  && (
            <span className="contactUs" onClick={handleShowContact}>
              {contactText}
            </span>
          )}
          <div className="navbar-brand mb-0 h1 button">
            <Button />
          </div>
        </div>
      </nav>
      <AnimatePresence className="event-container">
        {showEvents && (
          <>
            <div className="events">
              <motion.p
                animate={{opacity:1}} 
                exit={{opacity:0}} 
                className="item rounded p-1 bg-dark border border-success animate__animated animate__slideInRight" id="programEventId">
                <b className="Programmatitext">Programmati</b>
              </motion.p>
              {showComingsoon && (
              <motion.p
                animate={{opacity:1}} 
                exit={{opacity:0}} 
                id="comingSoonId" 
                className="item rounded p-1 bg-dark border border-warning text-warning animate__animated animate__fadeIn">
                <b className="Comingsoontext">Coming Soon</b>
              </motion.p>
          )}
              {events.map((event, index) => {
                return (
                  <motion.div animate={{opacity:1}} exit={{opacity:0}}>
                    <Event key={index} {...event} />
                  </motion.div>
                );
              })}
            </div>
            <motion.p 
              animate={{opacity:1}} 
              exit={{opacity:0}} 
              className="item rounded p-1 bg-dark border border-success animate__animated animate__slideInRight" id="pastEventId">
              <b className="Conclusitext">Conclusi:</b>
              <div id="filterEventsButtonsContainer" style={{ display:"flex", padding:"10px", flexDirection:"column-reverse"}} >
                <button onClick={()=>handleSelectedYear("2023")} className='btn btn-success' style={{marginLeft:"2px", marginTop:"5px"}}>2023</button>
                <button onClick={()=>handleSelectedYear("2024")} className='btn btn-success' style={{marginLeft:"2px", marginTop:"5px"}}>2024</button>
                <button onClick={()=>handleSelectedYear("2025")} className='btn btn-success'style={{marginLeft:"2px"}}>2025</button>
             </div>
            </motion.p>
            <motion-div
              alimate={{opacity:0}}
              exit={{opacity:1}}
            >
              {filteredPastEvents.map((pastEvent, index) => {
                return (
                    <Pastevent
                     key={index} 
                     {...pastEvent}
                     />
                )
              })}
             </motion-div>
          </>
        )}
      </AnimatePresence>
        <AnimatePresence className="contact-container">
           {
           showContact && 
            <motion.div
              animate={{opacity:1}}
              exit={{opacity:0, transition:{duration:0.8}}}
            >
             <Contact />
           </motion.div>
           }
        </AnimatePresence>
        <AnimatePresence>
          {showGroupInfo && (
            <motion.div 
              initial={{opacity:0}} 
              animate={{opacity:1}} 
              exit={{opacity:0}}
              className="group-info-container">
              <Group {...navbar} />
            </motion.div>
          )}
        </AnimatePresence>
    </>
  );
};

export default Nav;
